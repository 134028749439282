<template>
  <div class="food-list">
    <div class="top">
      <h2>{{ title }}</h2>
      <span>{{ type }}</span>
    </div>
    <header-search
      ref="headerSearch"
      placeholder="输入餐厅名称搜索"
      @search="search"
    />
    <div class="st-wrapper">
      <van-swipe-cell v-for="(food, index) in foodsList" :key="index">
        <div class="food-item" @click="handleEditFood(food)">
          <img :src="food.imageUrl" alt="" />
          <div class="right">
            <div class="name">{{ food.name }}</div>
            <div class="category">
              食材类目: <span>{{ food.catalog }}</span>
            </div>
          </div>
        </div>
        <template #right>
          <div class="del-btn" @click="handleDelFood(food, index)">
            <span>删除</span>
          </div>
        </template>
      </van-swipe-cell>
    </div>
    <footer-button text="新增食材" @confirm="confirm" />
  </div>
</template>
<script>
import { SwipeCell, Dialog } from 'vant'
import HeaderSearch from '../../../../components/header/search/Index'
import FooterButton from '../../../../components/footer/button/Index'
export default {
  components: {
    [SwipeCell.name]: SwipeCell,
    [Dialog.name]: Dialog,
    HeaderSearch,
    FooterButton
  },
  data () {
    return {
      title: '',
      type: '',
      foodsList: [],
      oldList: []
    }
  },
  created () {
    const { title, type, supplyId } = this.$route.query
    this.title = title
    this.type = type
    this.getFoodList(supplyId)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      // 获取滚动时的高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const dom = this.$refs.headerSearch.$el.querySelector('.search-item')
      if (scrollTop >= 20) {
        dom.style.top = '0'
      } else {
        dom.style.top = ''
      }
    },
    // 获取供应商在售食材列表
    getFoodList (supplyId, isSearch = false) {
      this.$api.supplier.foodList({ supplyId: supplyId }).then(res => {
        // console.log('获取供应商在售食材列表:', res.data.data)
        if (res.data.success) {
          if (isSearch) {
            this.oldList = res.data.data
          } else {
            this.oldList = this.foodsList = res.data.data
          }
        } else {
          console.log(res.message)
        }
      })
    },
    // 编辑食材 跳转
    handleEditFood (item) {
      // console.log(item)
      const { bid, title, type, supplyId } = this.$route.query
      this.$router.push({
        name: 'AddFood',
        query: {
          bid, title, type, supplyId, foodInfo: item
        }
      })
    },
    // 删除食材
    handleDelFood (item, index) {
      Dialog.confirm({
        message: '确定删除食材吗'
      })
        .then(() => {
          this.$api.supplier.delFood({ id: item._id }).then(res => {
            // console.log(res)
            if (res.data.success) {
              this.foodsList.splice(index, 1)
              this.getFoodList(this.$route.query.supplyId, true)
            }
          })
        })
    },
    search (value) {
      // console.log(value)
      let list = this.oldList
      if (value) {
        list = list.filter(item => {
          return item.name.indexOf(value) !== -1
        })
        console.log(list)
        this.foodsList = list
      } else {
        this.foodsList = list
      }
    },
    confirm () {
      const { bid, title, type, supplyId } = this.$route.query
      this.$router.push({
        name: 'AddFood',
        query: {
          bid, title, type, supplyId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
