<template>
  <div class="header-search">
    <div
      class="search-item"
      :class="search.display === 'fixed' ? 'fixed' : ''">
      <slot></slot>
      <div class="st-search-input">
        <input
          type="text"
          v-model="searchText"
          :placeholder="placeholder"
        >
        <b @click="onSearchBtn"></b>
      </div>
    </div>
    <div class="search-h"></div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    placeholder: {
      type: String,
      default: () => '输入内容搜索'
    }
  },
  data () {
    return {
      search: {
        display: 'auto'
      },
      searchText: ''
    }
  },
  mounted () {
    window.addEventListener('scroll', this.watchScroll)
  },
  methods: {
    // 监听滚动事件
    watchScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 20) {
        this.search.display = 'fixed'
      } else {
        this.search.display = 'auto'
      }
    },

    onSearchBtn () {
      this.$emit('search', this.searchText)
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
