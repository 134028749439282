<template>
  <div class="footer-wrapper">
    <div class="footer-h"></div>
    <div class="footer-main">
      <b @click="confirm">{{ text }}</b>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    text: { // 按钮文本
      type: String,
      default: () => '确定'
    }
  },
  data () {
    return {}
  },
  methods: {
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="less" scoped>
@import './index';
</style>
